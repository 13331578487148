<template>
<div id="app">
  <div class="body">
    <a
      class="body__authorize"
      href="https://vendorcentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.a7cc103d-1f2c-451a-b2e0-c59456623aad&state=stateexample"
    >
      Authorize
    </a>
  </div>
</div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" src="./assets/scss/style.scss" />
